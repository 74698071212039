import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../../../i18n";/* Adding new language */

const PricingComponent = () => {
  
  const {t} = useTranslation()
  const {pricing} = t("wireless"); /* Handle additional languages */
  
  const [selectedPlan, setSelectedPlan] = useState('10GB');

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  return (
    <div className='text-[#FFF] bg-[#0a3b60]'>
        <div className='max-w-[700px] mt-[0px] w-screen h-full mx-auto text-center flex flex-col justify-center py-6'>
            <h2 className='text-[#FFf] lg:text-5xl md:text-4xl sm:text-3xl text-2xl font-bold p-1 sm:pb-2'>
              {pricing.title}
            </h2>
            <h4 className='text-[#FF8916] text-3xl font-bold p-2 sm:pb-2'>
              {pricing.subtitle}
            </h4>

        <div className='flex sm:flex-row flex-col justify-center items-center mx-6'>
            <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-2 md:ml-4 ml-2 md:my-4 my-1 md:min-w-[540px]'>
            <span className='text-xl md:text-2xl font-normal align-text-top'>{pricing.price.line1}   </span>
            {selectedPlan === '10GB' && '$38.50'}
            {selectedPlan === '20GB' && '$45'}
            {selectedPlan === '50GB' && '$76'}
            {selectedPlan === '100GB' && '$155'}
            <span className='text-xl md:text-2xl font-semibold align-text-bottom'> {pricing.price.line2}</span>
            </h1>
        </div>
        
        <div>
            <h4 className='md:text-2xl sm:text-xl font-normal text-[#fff] py-4 px-4 justify-center'>
            <span className='text-xl md:text-2xl font-extrabold text-[#ff8916]'>
            {selectedPlan === '10GB' && '10'}
            {selectedPlan === '20GB' && '20'}
            {selectedPlan === '50GB' && '50'}
            {selectedPlan === '100GB' && '100'}
            <span>{pricing.gb}*</span></span> {pricing.description.line1}
            <br/>{pricing.description.line2a} <span className='text-xl md:text-2xl font-bold'>{pricing.description.line2b}</span> {pricing.description.line2c}
            </h4>
        </div>

        <div className="flex justify-around">
          <button
            className={`bg-[#0a3b60] hover:bg-[#0a3b60]-700 w-[100px] text-[#fff] border-2 rounded-md font-medium mt-8 mb-4 mx-4 py-3 ${
              selectedPlan === '10GB' ? 'bg-[#FF8916]' : ''
            }`}
            onClick={() => handlePlanSelect('10GB')}
          >
            10 <span>{pricing.gb}</span>
          </button>
          <button
           className={`bg-[#0a3b60] hover:bg-[#0a3b60]-700 w-[100px] text-[#fff] border-2 rounded-md font-medium mt-8 mb-4 mx-4 py-3 ${
              selectedPlan === '20GB' ? 'bg-[#FF8916]' : ''
            }`}
            onClick={() => handlePlanSelect('20GB')}
          >
            20 <span>{pricing.gb}</span>
          </button>
          <button
          className={`bg-[#0a3b60] hover:bg-[#0a3b60]-700 w-[100px] text-[#fff] border-2 rounded-md font-medium mt-8 mb-4 mx-4 py-3 ${
              selectedPlan === '50GB' ? 'bg-[#FF8916]' : ''
            }`}
            onClick={() => handlePlanSelect('50GB')}
          >
            50 <span>{pricing.gb}</span>
          </button>
          <button
            className={`bg-[#0a3b60] hover:bg-[#0a3b60]-700 w-[100px] text-[#fff] border-2 rounded-md font-medium mt-8 mb-4 mx-4 py-3 ${
              selectedPlan === '100GB' ? 'bg-[#FF8916]' : ''
            }`}
            onClick={() => handlePlanSelect('100GB')}
          >
            100 <span>{pricing.gb}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;