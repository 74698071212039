import React from 'react';
import CTA from '../../components/CTA';
import Hero from './components/Hero'
import ContactCTA from '../../components/CTA_ContactUs';
import Partners from '../homePage/components/partners';
import Communications from './components/communications';
import Ccaas from './components/ccaas'
import Telephony from './components/telephony'

const BusinessComPage = () => {
  
  return (
    <div>

        <Hero />
        <Communications /> 
        <Ccaas />  
        <Telephony />   
        <CTA />
        <Partners />
        <ContactCTA />
        
    </div>
    );
}

export default BusinessComPage