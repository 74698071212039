import React from 'react';
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../../../i18n";/* Adding new language */
import BroadBand from '../assets/internet-network-Broadband.jpg';

const FeatureBroadband = () => {
    
    const {t} = useTranslation()
    const {features} = t("internet"); /* Handle additional languages */
    
    return (
        <div className='w-full bg-[#fff] py-2 px-4'>
            <div className='max-w-[600px] mx-auto flex flex-col'>

                <div className='align-middle justify-center mx-4 my-2 sm:my-0 py-2 sm:py-0'>
                <img className='max-h-[330px] rounded-[10%] py-3' src={BroadBand} alt="Broad Band Internet" />
                </div>

                <div className='mx-6 align-middle max-h-[95%]'>
                    <h3 className='text-[#0A3B60] md:text-5xl sm:text-4xl text-3xl font-bold pt-3 text-left'>
                        {features.broadband.title}
                    </h3>
                    <h5 className='text-[#FF8916] text-xl md:text-2xl font-bold text-left'>
                        {features.broadband.subtitle}
                    </h5>
                    <p className='text-[#01489C] w-full max-w-[540px] text-lg py-4 text-left'>
                        {features.broadband.description.line1}<br/><br/>
                        {features.broadband.description.line2.line2a}<br/>
                        {features.broadband.description.line2.line2b}<br/>
                        {features.broadband.description.line2.line2c}<br/><br/>
                        {features.broadband.description.line3}
                    </p>
                </div>

            </div>
        </div>
     );
};

export default FeatureBroadband;