import React from 'react';
import Hero from './components/Hero'
import HostedPbxCTA from '../../components/CTA_HostedPbx';
import CTA from '../../components/CTA';
import ContactCTA from '../../components/CTA_ContactUs';
import Partners from '../homePage/components/partners';
import FeatureAgent from './components/featureAgent';
import FeatureArchive from './components/featureArchive';
import FeatureCampaign from './components/featureCampaign'
import FeatureRecording from './components/featureRecording'
import FeatureCrm from '../hostedPbx/components/featureCrm'

function ContactCenter() {
  return (
    <div>

      <Hero />
      <HostedPbxCTA />

      <FeatureAgent />
      <FeatureArchive />
      <FeatureCampaign />
      <FeatureRecording />
      <FeatureCrm />
               
      <CTA />
      <Partners />
      <ContactCTA />

    </div>
  );
}

export default ContactCenter;
