import React from 'react';
import Hero from './components/Hero'
import ContactCTA from '../../components/CTA_ContactUs';
import FeatureFiber from './components/FeatureFiber';
import FeatureBroadband from './components/FeatureBroadband';
import FeatureWireless from './components/FeatureWireless';

function Internet() {
  return (
    <div>

      <Hero />
      <div className='flex flex-col lg:flex-row'>
      <FeatureFiber />     
      <FeatureBroadband />
      <FeatureWireless />
      </div>
      <ContactCTA />

    </div>
  );
}

export default Internet;
