import React from 'react';
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../../../i18n";/* Adding new language */
import SMS from '../assets/SMS-Front.png'

const FeatureSms = () => {
    
    const {t} = useTranslation()
    const {features} = t("hostedpbx"); /* Handle additional languages */
    
    return (
        <div className='w-full bg-[#fff] py-4 px-4 my-6'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>   
                <div className='mx-6 align-middle max-h-[95%]'>
                    <h3 className='text-[#ff8916] md:text-5xl sm:text-4xl text-3xl font-bold p-6 text-left'>
                        {features.sms.title}
                    </h3>
                    <h5 className='text-[#0a3B60] w-full max-w-[500px] text-xl md:text-2xl p-4'>
                        {features.sms.subtitle}
                    </h5>

                    <div className='bg-[#0a3B60] shadow-2xl rounded-2xl max-w-fit'>
                    <p className='text-[#fff] w-full max-w-[540px] text-xl p-4 text-left'>
                        {features.sms.description.line1}<br/>
                        {features.sms.description.line2}
                    </p>
                    </div>
                    
                </div>

                <div className='align-middle justify-center mx-4 my-4 md:my-0 py-6 md:py-0 hidden md:block'>
                <img className='max-h-[330px] rounded-[10%]' src={SMS} alt="SMS & MMS" />
                </div>
            </div>
        </div>
     );
};

export default FeatureSms;