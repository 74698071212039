import React from 'react';
import NotFoundPage from './NotFoundPage';


const TestPage = () => {
  return (
    <div>
      <NotFoundPage />
      
    </div>
    );
};

export default TestPage;