import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import "../../i18n";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    utmCode: window.location.search.substring(1), // Get the UTM code from the URL
  });

  const [isVerified, setIsVerified] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

 if (!isVerified) {
      alert("Please verify that you are not a robot!");
      return;
    }

    const formData = new FormData(e.target);

    // Add your web3form access key to the form data
    formData.append("access_key", "a0e40f2d-0666-4d2f-9323-8971599c100b");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });

      const data = await response.json();

      if (data.success) {
        alert('Form submitted successfully!');
        window.location.href = '/';
      } else {
        alert('An error occurred while submitting the form. Please try again later.');
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again later.');
    }
  };

  const handleRecaptchaChange = (value) => {
    setIsVerified(value ? true : false);
  };

  return (
    <div className="w-full max-w-lg mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      {/* Form Content */}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
            Phone
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="phone"
            type="tel"
            placeholder="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
            Message
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="message"
            placeholder="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        
        {/* Hidden field for UTM code */}
        <input
          type="hidden"
          name="utmCode"
          value={formData.utmCode}
        />

        {/* Add reCAPTCHA */}
        <div className="mb-4">
          <ReCAPTCHA
             //Replace text with the current REcaptcha site key from Google
            onChange={handleRecaptchaChange}
          />
        </div>

        {/* Form Submission Button */}
        <div className="flex items-center justify-between">
          <button
            className="bg-[#ff8916] hover:opacity-75 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            //disabled={!isVerified} // Disable the button if not verified
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
