import React from 'react'
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../i18n";/* Adding new language */
const CTA = () => {

  const {t} = useTranslation()
  const {mainCta} = t("cta"); /* Handle additional languages */
  const {expert} = t("button"); /* Handle additional languages */

  return (
    <div className='bg-[#0A3B60] w-full py-16 text-[#fff] px-4'>
        <div className='max-w-[1240px] mx-auto grid grid-col-2 justify-center'>
            <div className='my-4 flex flex-col sm:flex-row items-center justify-between w-full'>
                <h2 className='lg:text-6xl md:text-4xl text-3xl px-6'>{mainCta}?</h2>
                <div className='flex flex-col justify-center align-middle mx-6'>
                  <button className='bg-[#FF8916] hover:opacity-75 text-[#fff] rounded-md font-medium w-[180px] ml-4 my-6 mx-auto py-3'>
                  <Link to="/ContactUs">{expert}</Link>
                  </button>
                </div>
            </div>        
        </div>
    </div>
  )
}

export default CTA