import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../i18n";/* Adding new language */
const CardsPartnerTypes = () => {
  
  const {t} = useTranslation()
  const {header, agent, reseller, whiteLabel} = t("partnerType"); /* Handle additional languages */
  
  return (

    <div className='w-full py-[2rem] px-4 bg-[#0a3b60] justify-center'>
 
      <h3 className='text-[#ff8916] lg:text-4xl md:text-3xl text-2xl font-bold pb-8 px-8 text-center'>{header}</h3>
        
        <div className='max-w-[1240px] mx-auto flex flex-col lg:flex-row'>
          
          <button className='w-[360px] lg:w-[450px] shadow-2xl flex flex-col bg-[#fff] p-2 my-4 mx-2 md:mx-6 rounded-lg hover:scale-105 duration-300'>
          <Link to="/ContactUs">
          <h4 className='text-2xl text-center font-bold pt-6 text-[#0a3b60]'>{agent.subtext}</h4>
          <h2 className='text-4xl md:text-5xl font-bold text-center py-4 px-3 text-[#ff8916]'>{agent.title}</h2>
            <div className='text-center text-lg font-semibold text-[#0a3b60]'>
              <p className='pb-4 mx-8 mt-4'>{agent.description.line1}
              <br/><br/>{agent.description.line2}</p>
            </div>
          </Link>
          </button>
  
          <button className='w-[360px] lg:w-[450px] shadow-2xl flex flex-col bg-[#fff] p-2 my-4 mx-2 md:mx-6 rounded-lg hover:scale-105 duration-300'>
          <Link to="/ContactUs">
          <h4 className='text-2xl  text-center font-bold pt-6 text-[#0a3b60]'>{reseller.subtext}</h4>
          <h2 className='text-4xl md:text-5xl font-bold text-center py-4 text-[#ff8916]'>{reseller.title}</h2>
            <div className='text-center text-lg font-semibold text-[#0a3b60]'>
              <p className='pb-4 mx-8 mt-4'>{reseller.description.line1} 
              <br/><br/>{reseller.description.line2}</p>
            </div>
          </Link>
          </button>
          
          <button className='w-[360px] lg:w-[450px] shadow-2xl flex flex-col bg-[#fff] p-2 my-4 mx-2 md:mx-6 rounded-lg hover:scale-105 duration-300'>
          <Link to="/ContactUs">
          <h4 className='text-2xl text-center font-bold pt-6 text-[#0a3b60]'>{whiteLabel.subtext}</h4>
          <h2 className='text-4xl md:text-5xl font-bold text-center py-4 text-[#ff8916]'>{whiteLabel.title}</h2>
            <div className='text-center text-lg font-semibold text-[#0a3b60]'>
              <p className='pb-4 mx-8 mt-4'>{whiteLabel.description.line1} 
              <br/><br/>{whiteLabel.description.line2}</p>
            </div>
          </Link>
          </button>
        </div>
    </div>
  )
}

export default CardsPartnerTypes