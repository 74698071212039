import React, { createContext, useContext, useState } from 'react';

// Create a context for managing the current locale
const LocaleContext = createContext();

// Custom hook for accessing the current locale and setting a new locale
export const useLocale = () => {
  return useContext(LocaleContext);
};

// Provider component to wrap the application and manage the current locale
export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState('en'); // Default to English

  const changeLocale = (newLocale) => {
    setLocale(newLocale);
  };

  return (
    <LocaleContext.Provider value={{ locale, changeLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleContext;