import React from 'react';
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../../../i18n";/* Adding new language */
import UcDevices from '../assets/UC-devices-mockup-communicator.png';

const ServiceUC = () => {
  
  const {t} = useTranslation()
  const {service} = t("comm_page");
  const {learn} = t("button");
   
  return (
    <div className='w-full bg-[#fff] my-2 md:my-6 py-4 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        
        <div className='align-middle justify-center mx-4 my-4 sm:my-0 p-6 sm:py-0 hidden md:block'>
        <img className='max-h-[350px] rounded-[10%]' src={UcDevices} alt="Unified Communications" />
        </div>

        <div className='mx-6 align-middle max-h-[600px] pl-4'>

          <h4 className='text-[#FF8916] text-xl md:text-2xl font-semibold'>
          {service.ucaas.subtext}</h4>

          <h3 className='text-[#0A3B60] lg:text-5xl md:text-4xl text-3xl font-bold py-3'>
          {service.ucaas.title}</h3>

          <h5 className='text-[#01489C] w-full max-w-[570px] text-normal md:text-lg px-2 font-bold'>
          {service.ucaas.subtitle}</h5>

          <p className='text-[#01489C] w-full max-w-[570px] text-normal md:text-lg px-2'>
          {service.ucaas.description.line1}
          {service.ucaas.description.line2}
          </p>

          <button className='bg-[#FF8916] hover:opacity-75 w-[150px] text-[#fff] font-medium my-2 sm:my-6 py-2'>
          <Link to="/BusinessCommunications/HostedPbx">{learn}</Link></button>   

        </div>

      </div>
    </div>
  );
};

export default ServiceUC;