import React from 'react'
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../../../i18n";/* Adding new language */

const Hero = () => {
  
  const {t} = useTranslation()
  const {hero} = t("wireless"); /* Handle additional languages */

   return (
    <div className='text-[#0a3b60] bg-[#fff]'>
        <div className='lg:max-w-[1100px] mt-[0px] mb-[16px] w-screen h-full mx-auto text-center flex flex-col justify-center py-12'>
            <h3 className='text-[#0a3b60] lg:text-3xl md:text-2xl sm:text-xl font-semibold p-1 sm:pb-2'>{hero.subtitle}</h3>
            
            <div className='flex sm:flex-row flex-col justify-center items-center mx-6'>
            <h2 className='lg:text-7xl md:text-5xl sm:text-4xl text-3xl font-bold md:py-2 md:ml-4 ml-2 md:my-2 my-1 sm:min-w-[450px]'><span className='text-[#ff8916]'>{hero.title.line1}</span><br/>{hero.title.line2}</h2>
            </div>

            <div className='pb-8'>
              <p className='lg:text-3xl md:text-xl sm:text-xl font-normal text-[#0a3b60] py-2 px-6 '>
              {hero.description.line1}<br/><span className='text-[#ff8916] font-bold'>{hero.description.line2}</span><br/><br/>{hero.description.line3}</p>
            </div>
            
    </div>
    </div>
    
  )
}

export default Hero
