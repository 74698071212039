import React from 'react';
import {Link} from "react-router-dom";
import { ReactTyped } from 'react-typed';
import { MdOutlineContactPhone, MdNetworkCheck, MdMobileFriendly } from "react-icons/md";
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../../../i18n";/* Adding new language */

  const Hero = () => {
  
    const {t} = useTranslation()
    const {hero} = t("homepage"); /* Handle additional languages */
    

   return (
    <div className='text-[#FFF] bg-[#0a3b60]'>
        <div className='lg:max-w-[1100px] mt-[0px] mb-[16px] w-screen h-full mx-auto text-center flex flex-col justify-center py-12'>
            <h2 className='text-[#FFf] lg:text-3xl md:text-2xl sm:text-xl font-semibold p-3 sm:pb-6'>{hero.subtitle.line1}<span className='text-[#ff8916]'> {hero.subtitle.line2}</span> {hero.subtitle.line3}</h2>
            
            <div className='flex sm:flex-row flex-col justify-center items-center mx-6'>
              <h1 className='lg:text-7xl md:text-5xl sm:text-4xl text-3xl font-bold md:py-2 md:ml-4 ml-2 md:my-2 my-1 sm:min-w-[450px]'>{hero.title.line1}<br/> 
              <ReactTyped 
              className='lg:text-8xl md:text-6xl sm:text-5xl text-4xl font-bold md:py-2 text-[#FF8916] md:ml-4 ml-2 md:my-2 my-1 text-left'
              strings={[
                (hero.title.line2.communications),
                (hero.title.line2.voip),
                (hero.title.line2.ucaas),
                (hero.title.line2.telephony),
                (hero.title.line2.internet),
                (hero.title.line2.wireless),
                ]}
              typeSpeed={1} 
              backDelay={4999}
              showCursor={false}
              backSpeed={1} 
              loop
              /></h1>
            </div>

            <div className='pb-8'>
              <p className='lg:text-3xl md:text-xl sm:text-xl font-normal text-[#fff] py-4 px-6 '>
              {hero.description.line1} 
              <br/><br/>{hero.description.line2}</p>
            </div>
            
            <div className='flex flexrow'>          
            <button className='w-[300px] shadow-2xl hidden md:flex flex-col bg-gray-100 p-1 my-2 mx-4 md:mx-9 rounded-lg hover:scale-105 duration-300 opacity-85'>
            <Link to="/BusinessCommunications">
              <h3 className='text-3xl font-bold text-center py-2 text-[#ff8916]'>
              <MdOutlineContactPhone size={50} className='w-20 mx-auto mt-[1rem] bg-transparent text-[#0A3B60]' />{hero.cards.title1}</h3>
              <div className='text-center font-medium text-[#0A3B60]'>
              <h5 className='py-2 mx-4 font-bold text-lg text-[#0A3B60 hidden lg:block'>{hero.cards.text1}</h5>
              </div>
            </Link></button>

            <button className='w-[300px] shadow-2xl hidden md:flex flex-col bg-gray-100 p-1 my-2 mx-4 md:mx-9 rounded-lg hover:scale-105 duration-300 opacity-85'>
            <Link to="/Internet">
              <h3 className='text-3xl font-bold text-center py-2 text-[#ff8916]'>
              <MdNetworkCheck size={50} className='w-20 mx-auto mt-[1rem] bg-transparent text-[#0A3B60]' />{hero.cards.title2}</h3>
              <div className='text-center font-medium text-[#0A3B60]'>
              <h5 className='py-2 mx-4 font-bold text-lg text-[#0A3B60] hidden lg:block'>{hero.cards.text2}</h5>
              </div>
            </Link></button>

            <button className='w-[300px] shadow-2xl hidden md:flex flex-col bg-gray-100 p-1 my-2 mx-4 md:mx-9 rounded-lg hover:scale-105 duration-300 opacity-85'>
            <Link to="/Wireless">
              <h3 className='text-3xl font-bold text-center py-2 text-[#ff8916]'>
              <MdMobileFriendly size={50} className='w-20 mx-auto mt-[1rem] bg-transparent text-[#0A3B60]' />{hero.cards.title3}</h3>
              <div className='text-center font-medium text-[#0A3B60]'>
              <h5 className='py-2 mx-4 font-bold text-lg text-[#0A3B60] hidden lg:block'>{hero.cards.text3}</h5>
              </div>
            </Link></button>
          </div>
    </div>
    </div>
    
  )
}

export default Hero