import React from 'react'
import { 
  MdOutlineContactPhone, 
  MdOutlineMessage, 
  MdGroups, 
  MdOutlineCheckCircleOutline, 
  MdOutlineWifiCalling,
  MdOutlineMms,
  MdOutlineAttachFile,
  MdOutlineVoicemail,
  MdVideoCameraFront,
  MdOutlineKeyboardVoice,
  MdPersonSearch,
  MdOutlineInsertChart,
  MdOutlinePlaylistAddCheck,
  MdAllInclusive,
} 
from "react-icons/md";
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../i18n";/* Adding new language */

const CardsUcFeatures = () => {
  
  const {t} = useTranslation()
  const {features} = t("cards"); /* Handle additional languages */
  
  return (
  <div className='text-[#FFF] bg-[#fff] '>
    <div className='lg:max-w-[1540px] w-screen h-full mx-auto text-center flex flex-col justify-center py-0 md:py-2'>       
      
    <div className='flex flex-col lg:flex-row'>
 
      <div className='flex flex-row justify-center'>
        <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <h3 className='text-sm md:text-xl text-center py-2 text-[#0A3B60]'>
          <MdOutlineMessage size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[orange]' />{features.messaging}</h3>
        </div>
        <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <h3 className='text-sm md:text-xl text-center text-[#0A3B60]'>
          <MdGroups size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[gray]' />{features.meetings}</h3>
        </div>
        <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <h3 className='text-sm md:text-xl text-center text-[#0A3B60]'>
          <MdOutlineCheckCircleOutline size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[#68a85b]' />{features.presence}</h3>
        </div>
        <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <h3 className='text-sm md:text-xl text-center text-[#0A3B60]'>
          <MdOutlineWifiCalling size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[#4173b6]' />{features.callquality}</h3>
        </div>
      </div>

      <div className='flex flex-row justify-center'>
          <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
            <h3 className='text-sm md:text-xl text-center text-[#0A3B60]'>
            <MdVideoCameraFront size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[orange]' />{features.conferencing}</h3>
          </div> 
          <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
            <h3 className='text-sm md:text-xl text-center py-2 text-[#0A3B60]'>
            <MdOutlineContactPhone size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[gray]' />{features.deskphone}</h3>
          </div>
          <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
            <h3 className='text-sm md:text-xl text-center py-2 text-[#0A3B60]'>
            <MdPersonSearch size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[#68a85b]' />{features.crm}</h3>
          </div>     
      </div>

    </div>

    <div className='flex flex-col lg:flex-row'>      
 
      <div className='flex flex-row justify-center'>
        <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <h3 className='text-sm md:text-xl text-center py-2 text-[#0A3B60]'>
          <MdOutlinePlaylistAddCheck size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[#4173b6]' />{features.setup}</h3>
        </div>
        <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <h3 className='text-sm md:text-xl text-center py-2 text-[#0A3B60]'>
          <MdAllInclusive size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[orange]' />{features.scalable}</h3>
        </div>
        <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <h3 className='text-sm md:text-xl text-center py-2 text-[#0A3B60]'>
          <MdOutlineKeyboardVoice size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[gray]' />{features.recording}</h3>
        </div>
        <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <h3 className='text-sm md:text-xl text-center py-2 text-[#0A3B60]'>
          <MdOutlineInsertChart size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[#68a85b]' />{features.dashboard}</h3>
        </div>
      </div>
      
      <div className='flex flex-row justify-center'>
        <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <h3 className='text-sm md:text-xl text-center text-[#0A3B60]'>
          <MdOutlineMms size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[#4173b6]' />{features.sms}</h3>
        </div>
        <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <h3 className='text-sm md:text-xl text-center text-[#0A3B60]'>
          <MdOutlineAttachFile size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[orange]' />{features.filetransfer}</h3>
        </div>
        <div className='w-[60px] sm:w-[160px] flex flex-col p-2 my-1 mx-5 md:mx-6 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <h3 className='text-sm md:text-xl text-center text-[#0A3B60]'>
          <MdOutlineVoicemail size={75} className='w-14 sm:w-16 mx-auto bg-transparent text-[gray]' />{features.voicemail}</h3>
        </div>
      </div>

    </div>

    </div>
  </div>
    
  )
}

export default CardsUcFeatures