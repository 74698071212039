import React, { useState } from 'react';
import { AiOutlineMenu, AiOutlineClose, AiOutlineGlobal } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'; /* Adding new language */
import LanguageSelector from '../components/LanguageSelector'; /* Adding new language */
import "../i18n";/* Adding new language */

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
  };

  const [LangMenu, setLangMenu] = useState(false);
    const handleLangMenu = () => {
    setLangMenu(!LangMenu)
  };

  const {t} = useTranslation()
  const {communications, hostedpbx, contactcenter, telephony, internet, wireless, partners, contact} = t("menu"); /* Handle additional languages */

  const DropdownMenu = () => {
 
  return (
    <ul className="absolute w-[330px] top-[78px] bg-[#f3f3f3] text-[#0a3b60] border-2 border-t-0 opacity-95 py-1 px-4 ml-[-16px]">
      <li className="pt-3 pb-2 px-3 border-b-2 hover:text-[#0a3b60c7]"><Link to="/BusinessCommunications/HostedPbx">{hostedpbx}</Link></li>
      <li className="py-2 px-3 border-b-2 hover:text-[#0a3b60c7]"><Link to="/BusinessCommunications/ContactCenter">{contactcenter}</Link></li>
      <li className="pt-2 pb-3 px-3 hover:text-[#0a3b60c7]"><Link to="/BusinessCommunications/Telephony">{telephony}</Link></li>
    </ul>
  );
};

  return (
    <nav className="bg-[#0a3b6011] flex justify-between items-center h-24 mx-auto pl-2 pr-2 text-[#0a3b60]">
      <h2 className="lg:ml-48 lg:mr-16 w-full text-5xl font-extrabold text-[#FF8916] pb-4">
        <Link to="/">TEL<span className="text-[#0a3b60]">Synergy</span></Link>
      </h2>
      <ul className="hidden md:flex lg:mr-48">
        <li className="py-4 px-6 text-lg font-[700] lg:text-xl hover:text-[#0a3b60c7]">
          <div className="relative">
            <button className="focus:outline-none text-center" onClick={handleNav} >
            {communications}
            </button>
            <div onClick={handleNav} onMouseLeave={handleNav}>{nav && <DropdownMenu />}</div>
          </div>
        </li>
        <li className="py-4 px-8 text-lg font-[700] lg:text-xl text-center hover:text-[#0a3b60c7]"><Link to="/Internet">{internet}</Link></li>
        <li className="p-4 px-8 text-lg font-[700] lg:text-xl text-center hover:text-[#0a3b60c7]"><Link to="/Wireless">{wireless}</Link></li>
        <li className="p-8 text-lg font-[700] lg:text-xl text-center hover:text-[#0a3b60c7]"><Link to="/Partners">{partners}</Link></li>
        <li className="p-8 text-lg font-[700] lg:text-xl text-center hover:text-[#0a3b60c7]"><Link to="/ContactUs">{contact}</Link></li>
        {/*<li className="p-8 text-lg font-[700] lg:text-xl text-center hover:text-[#0a3b60c7]"><a href="https://ucaas.tel-synergy.com/?utm_source=TelSynergyWeb">{contact}</a></li>*/}
      </ul>
      
      <ul>
        <li className="p-4 text-lg font-[700] lg:text-xl text-center hover:text-[#0a3b60c7] mr-42">
          <div className="relative h-[25px]">
          <button onClick={handleLangMenu} >
          <AiOutlineGlobal size={25} />
          </button>
          <div onClick={handleLangMenu} onMouseLeave={handleLangMenu}>{LangMenu && <LanguageSelector/>}</div>
          </div>
        </li>
      </ul>

       <div onClick={handleNav} className="block md:hidden mr-6">
        {!nav ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
      
        <div className={!nav ? 'z-10 absolute left-0 top-24 w-[100%] sm:hidden h-[600px] bg-[#0a3b60] text-[#fff] opacity-[99%] ease-out duration-500' : 'fixed left-[-100%]' }>
             <ul className='p-4 md:hidden'>
              <li onClick={handleNav} className='p-4 text-lg font-[700] lg:text-xl'><Link to="/BusinessCommunications/HostedPbx">{hostedpbx}</Link></li>
              <li onClick={handleNav} className='p-4 text-lg font-[700] lg:text-xl'><Link to="/BusinessCommunications/ContactCenter">{contactcenter}</Link></li>
              <li onClick={handleNav} className='p-4 text-lg font-[700] lg:text-xl'><Link to="/BusinessCommunications/Telephony">{telephony}</Link></li>
              <li onClick={handleNav} className='p-4 text-lg font-[700] lg:text-xl'><Link to="/Wireless">{wireless}</Link></li>
              <li onClick={handleNav} className='p-4 text-lg font-[700] lg:text-xl'><Link to="/Internet">{internet}</Link></li>
              <li onClick={handleNav} className='p-4 text-lg font-[700] lg:text-xl'><Link to="/Partners">{partners}</Link></li>
              <li onClick={handleNav} className='p-4 text-lg font-[700] lg:text-xl'><Link to="/ContactUs">{contact}</Link></li>
            </ul>  
        </div>
      </div>
    </nav>
  );
};

export default Navbar;