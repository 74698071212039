import React from 'react';
import Hero from './components/Hero'
import CardsPartnerTypes from '../../components/CardsPartnerTypes';
import FeatureSmartbilling from './components/featureSmartbilling';
import FeatureWhitelabel from './components/featureWhitelabel';
import Telecom from './components/Telecom'
import CTA from '../../components/CTA';

function Partners() {
  return (
    <div>

      <Hero />
      <CardsPartnerTypes />
      <Telecom />   
      <FeatureWhitelabel />
      <FeatureSmartbilling />
      <CTA />
      
    </div>
  );
}

export default Partners;
