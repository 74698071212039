import React from 'react';
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../i18n";/* Adding new language */;

const CardsPlansInclude = () => {
    
    const {t} = useTranslation()
    const {plansinclude} = t("cards"); /* Handle additional languages */
    
    return (
        <div className='text-[#ff8916] bg-[#fff]'>
            <div className='max-w-[1540px] w-screen h-full mx-auto text-center flex flex-col justify-center py-4'>
                <div className='flex sm:flex-row flex-col justify-center items-center mx-6'>
                    <h2 className='md:text-5xl sm:text-4xl text-3xl font-bold md:py-2 md:ml-4 ml-2 my-6 md:min-w-[540px]'>{plansinclude.title}</h2>
                </div>
               <div className='mx-auto flex flex-col sm:flex-row justify-center'>
                    <div className='w-[336px] h-[297px] mx-6 align-middle bg-[#0a3b60] px-6 rounded-xl my-4'>
                        <h3 className='text-[#fff] text-2xl font-bold pt-3 text-center md:text-left'>
                        {plansinclude.card1.subtitle}</h3> 
                        <p className='text-[#fff] w-full max-w-[540px] text-lg py-4 text-center md:text-left'>
                        {plansinclude.card1.description}</p>
                    </div>
                    <div className='w-[336px] h-[297px] mx-6 align-middle bg-[#0a3b60] px-6 rounded-xl my-4'>
                        <h3 className='text-[#fff] text-2xl font-bold pt-3 text-center md:text-left'>
                        {plansinclude.card2.subtitle}</h3> 
                        <p className='text-[#fff] w-full max-w-[540px] text-lg py-4 text-center md:text-left'>
                        {plansinclude.card2.description}</p>
                    </div>
                    <div className='w-[336px] h-[297px] mx-6 align-middle bg-[#0a3b60] px-6 rounded-xl my-4'>
                        <h3 className='text-[#fff] text-2xl font-bold pt-3 text-center md:text-left'>
                        {plansinclude.card3.subtitle}</h3> 
                        <p className='text-[#fff] w-full max-w-[540px] text-lg py-4 text-center md:text-left'>
                        {plansinclude.card3.description}</p>
                    </div>
                    <div className='w-[336px] h-[297px] mx-6 align-middle bg-[#0a3b60] px-6 rounded-xl my-4'>
                        <h3 className='text-[#fff] text-2xl font-bold pt-3 text-center md:text-left'>
                        {plansinclude.card4.subtitle}</h3> 
                        <p className='text-[#fff] w-full max-w-[540px] text-lg py-4 text-center md:text-left'>
                        {plansinclude.card4.description}</p>
                    </div>
                </div>
                <h3 className='lg:text-4xl text-3xl py-6 px-9 text-[#ff8916] font-bold'>{plansinclude.subtext.line1}<br/>
                <span className='text-[#0a3b60] '>{plansinclude.subtext.line2}</span></h3>
            </div>
        </div>
      )
}

export default CardsPlansInclude