import React from 'react';
import Hero from './components/Hero'
import CTA from '../../components/CTA';
import ContactCTA from '../../components/CTA_ContactUs';
import Communications from './components/communications'
import Internet from './components/internet'
import Wireless from './components/wireless'
import Partners from './components/partners'

function Home() {
  return (
    <div>

      <Hero />
      <Communications /> 
      <Internet />  
      <Wireless />  
      <CTA />
      <Partners />
      <ContactCTA />
     
    </div>
  );
}

export default Home;
