import React from 'react'
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../../i18n";/* Adding new language */
import ContactForm from './ContactForm'
import Communicator from './assets/communicator-go.png'

const ContactUs = () => {
  
  const {t} = useTranslation()
  const {title} = t("contact"); /* Handle additional languages */

  return (
    <div className='flex bg-[#0a3b60] py-24'>
      
      <div className='min-w-[399px] sm:min-w-[540px] sm:ml-24 flex-1 py-4 sm:py-8 px-8 align-middle'>   
        <ContactForm />
      </div>
      
      <div className='min-w-[540px] h-fit mr-24 flex-1 p-8 align-middle hidden lg:block'>
      <h2 className='lg:text-5xl md:text-4xl text-3xl px-6 py-8 text-[#fff]'>{title}</h2>
            <img className='h-full w-full hidden lg:flex pt-8'
            src={Communicator} alt="Communicator" />
            
      </div>
      
    </div>
  )
}

export default ContactUs
