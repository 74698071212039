import React from 'react'
import { /*MdOutlinePeopleAlt,*/ MdBusiness, MdOutlineContactPhone } from "react-icons/md";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../i18n";/* Adding new language */

const CardsPbxEditions = () => {
  
  const {t} = useTranslation()
  const {business, /*multitenant,*/ contactcenter} = t("pbxEditions"); /* Handle additional languages */
  const {demo} = t("button"); /* Handle additional languages */
 
  return (
    <div className='w-full py-[2rem] px-4 bg-[white] justify-center'>
      <div className='max-w-[1240px] mx-auto flex flex-col lg:flex-row'>
        
        <div className='w-[350px] sm:w-[570px] shadow-2xl flex flex-col bg-gray-100 p-4 my-4 mx-4 md:mx-9 rounded-lg hover:scale-105 duration-300 opacity-85'>
          <MdBusiness size={50} className='w-20 mx-auto mt-[1rem] bg-transparent text-[#ff8916]' />
          <h2 className='text-4xl font-bold text-center py-6 text-[#0A3B60]'>{business.title}</h2>
          <div className='text-left font-medium text-[#0A3B60]'>
            <h3 className='mx-4 font-bold text-xl text-center text-[#ff8916]'>{business.message}</h3>
            <br/>
            <ul>
              <li className='mx-2 py-2 text-lg'>{business.description.line1}</li>
              <li className='mx-2 py-2 text-lg'>{business.description.line2}</li>
              <li className='mx-2 py-2 text-lg'>{business.description.line3}</li>
            </ul>
            <br/>
          </div>
          <button className='bg-[#FF8916] text-[#fff] rounded-md font-medium text-lg w-[150px] my-4 mx-auto py-3'><Link to="/ContactUs">{demo}</Link></button>
        </div>
{/* We decided not to present Multitenant, We left this placeholder just in case

        <div className='w-[350px] sm:w-[570px] shadow-xl flex flex-col p-4 my-8 md:my-0 mx-4 md:mx-9 rounded-lg hover:scale-105 duration-300'>
          <MdOutlinePeopleAlt size={50} className='w-20 mx-auto mt-[1rem] bg-transparent text-[#ff8916]' />
          <h2 className='text-4xl font-bold text-center py-6 text-[#0A3B60]'>{multitenant.title}</h2>
          <div className='text-center font-medium text-[#0A3B60]'>
            <h3 className='mx-4 font-bold text-xl text-[#ff8916]'>{multitenant.message}</h3>
            <br/>
            <ul>
              <li className='mx-2 py-2 text-lg'>{multitenant.description.line1}</li>
              <li className='mx-2 py-2 text-lg'>{multitenant.description.line2}</li>
              <li className='mx-2 py-2 text-lg'>{multitenant.description.line3}</li>
            </ul>
            <br/>
          </div>
          <button className='bg-[#ff8916] hover:opacity-90 text-[#fff] rounded-md font-medium text-lg w-[150px] my-4 mx-auto py-3'><Link to="/ContactUs">{demo}</Link></button>
        </div>
  */}

        <div className='w-[350px] sm:w-[570px] shadow-2xl flex flex-col bg-gray-100 p-4 my-4 mx-4 md:mx-9 rounded-lg hover:scale-105 duration-300 items-center opacity-85'>
          <MdOutlineContactPhone size={50} className='w-20 mx-auto mt-[1rem] bg-transparent text-[#ff8916]' />
          <h2 className='text-4xl font-bold text-center py-6 text-[#0A3B60]'>{contactcenter.title}</h2>
          <div className='text-left font-medium text-[#0A3B60]'>
          <h3 className='mx-4 font-bold text-xl text-center text-[#ff8916]'>{contactcenter.message}</h3>
          <br/>
            <ul>
              <li className='mx-2 py-2 text-lg'>{contactcenter.description.line1}</li>
              <li className='mx-2 py-2 text-lg'>{contactcenter.description.line2}</li>
              <li className='mx-2 py-2 text-lg'>{contactcenter.description.line3}</li>
            </ul>
            <br/>
          </div>
          <button className='bg-[#FF8916] text-[#fff] rounded-md font-medium text-lg w-[150px] my-4 mx-auto py-3'><Link to="/ContactUs">{demo}</Link></button>
        </div>
      </div>
    </div>
  )
}

export default CardsPbxEditions

