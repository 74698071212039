import React from 'react';
import Hero from './components/Hero'
import CTA from '../../components/CTA';
import ContactCTA from '../../components/CTA_ContactUs';
import FeatureDID from './components/FeatureDid';
import FeatureToll from './components/FeatureToll';
import FeatureLD from './components/FeatureLongDistance';
import FeaturePRI from './components/FeaturePri';
import FeaturePSTN from './components/FeaturePstn'

function Telephony() {
  return (
    <div>

      <Hero />

      <div className='flex flex-col lg:flex-row'>
      <FeatureDID />
      <FeatureToll />
      <FeatureLD />
      </div>
      <CTA />
      <div className='flex flex-col lg:flex-row'>
      <FeaturePRI />
      <FeaturePSTN />
      </div>
      <ContactCTA />

    </div>
  );
}

export default Telephony;
