import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from './pages/Navbar';
import Footer from './pages/Footer';
import Home from './pages/homePage/HomePage';
import BusinessCommunications from './pages/businessCom/BusinessComPage';
import HostedPbx from './pages/hostedPbx/HostedPbxPage'
import ContactCenter from './pages/contactCenter/ContactCenterPage';
import Wireless from './pages/wireless/WirelessPage';
import Telephony from './pages/telephony/TelephonyPage';
import Internet from './pages/internet/InternetPage';
import Partners from './pages/partners/PartnersPage';
import NotFound from './pages/NotFoundPage';
import ContactUs from './pages/contact/ContactUs';
import ScrollToTop from './components/ScrollToTop';
import TestPage from './pages/TestPage';
  
function App() {

  return (
    <>
    <BrowserRouter>
    <ScrollToTop />
    <Navbar />
       <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/BusinessCommunications" element={<BusinessCommunications />} />
        <Route path="/BusinessCommunications/HostedPbx" element={<HostedPbx />} />
        <Route path="/BusinessCommunications/ContactCenter" element={<ContactCenter />} />
        <Route path="/BusinessCommunications/Telephony" element={<Telephony />} />
        <Route path="/Wireless" element={<Wireless />} />
        <Route path="/Internet" element={<Internet />} />
        <Route path="/Partners" element={<Partners />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Test" element={<TestPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    <Footer />
    </BrowserRouter>
    </>
  )
}

export default App;