import React from 'react'
import { 
    FaSquareFacebook,
    FaSquareXTwitter,
    FaLinkedin,
} from "react-icons/fa6";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../i18n";/* Adding new language */


const Footer = () => {

  const {t} = useTranslation()
  const {hostedpbx, contactcenter, telephony, internet, wireless, partners, contact} = t("menu"); /* Handle additional languages */

  return (
    <div className='bg-[#0a3b6011] flex flex-row justify-between items-center mx-auto px-9 text-[#0a3b60]'>
        <div className='lg:ml-48'>
          <h2 className='w-full text-4xl font-extrabold text-[#FF8916] pt-6'>TEL<span className='text-[#0a3b60]'>Synergy</span></h2>
          <p className='text-[#0a3b60] text-xl font-bold my-6'>
              TelSynergy Networks Inc.<br/>
              4255 Rue Ste-Catherine Ouest,<br/>
              Westmount, Quebec,<br/>
              Canada, H3Z 1P7
              <br className='sm:hidden'/>
              <span className='sm:hidden'>info@tel-synergy.com</span>
              <br/>
              <span className='sm:hidden'>+1-888-354-7499</span>
              </p>
          <div className='flex md:w-[75%] my-6 justify-between'>
            <a href='https://www.linkedin.com/company/tel-synergy-network-inc'><FaLinkedin size={30} /></a>
            <a href='https://www.facebook.com/telsynergyinc'><FaSquareFacebook size={30} /></a>
            <a href='https://www.x.com/'><FaSquareXTwitter size={30} /></a>
          </div>
        </div>
        <div className='lg:col-span-3 hidden sm:flex sm:flex-col justify-between'>
          
          <h6 className='py-6 mx-3 text-xl font-[700]'>{contact}</h6>
          <ul>
              <li className='py-2 mx-3 text-lg font-semibold'><a href='tel:+18883547499'>+1-888-354-7499</a></li>
              <li className='py-2 mx-3 text-lg font-semibold'><a href='tel:+15144487400'>+1-514-448-7400</a></li>
              <li className='py-2 mx-3 text-lg font-semibold'><a href='mailto:sales@tel-synergy.com'>sales@tel-synergy.com</a></li>
              <li className='py-2 mx-3 text-lg font-semibold'><a href='mailto:info@tel-synergy.com'>info@tel-synergy.com</a></li>
          </ul>
        </div>
        <nav className='lg:col-span-3 hidden sm:flex flex-col justify-between lg:mr-48'>
            <ul>
              <li className='pt-4 pb-2 mx-3 text-xl font-[500]'><Link to="/BusinessCommunications/HostedPbx">{hostedpbx}</Link></li>
              <li className='py-2 mx-3 text-xl font-[600]'><Link to="/BusinessCommunications/ContactCenter">{contactcenter}</Link></li>
              <li className='py-2 mx-3 text-xl font-[600]'><Link to="/BusinessCommunications/Telephony">{telephony}</Link></li>
              <li className='py-2 mx-3 text-xl font-[600]'><Link to="/Wireless">{wireless}</Link></li>
              <li className='py-2 mx-3 text-xl font-[600]'><Link to="/Internet">{internet}</Link></li>
              <li className='pb-4 pt-2 mx-3 text-xl font-[600]'><Link to="/Partners">{partners}</Link></li>
            </ul>
        </nav>
    </div>
    
  )
}

export default Footer