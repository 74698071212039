import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import "./i18n.js"

import App from './App';
import { LocaleProvider } from '../src/context/LocaleContext.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading...">
      <LocaleProvider>  
        <App />
      </LocaleProvider>
    </React.Suspense>
  </React.StrictMode>
);

