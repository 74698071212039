import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocale } from '../context/LocaleContext';

const languages = [
  {code: "en", lang: "English"},
  {code: "fr", lang: "Français"},
];

const LanguageSelector = () => {
  const {i18n} = useTranslation();
  const { changeLocale } = useLocale();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    changeLocale(lng);
  };

  useEffect(()=>{
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]); // This is used to support lanaguage direction, some languages write right to left, and others as arabic go left to right
  
  return (
    <div className="absolute top-[61px] bg-[#0a3b60] sm:bg-[#f3f3f3] text-[#f3f3f3] sm:text-[#0a3b60] border-t-0 py-0 px-3 ml-[-75px] sm:ml-[-98px]">
      {languages.map((lng) => (
            <button 
              className="hover:text-[#0a3b60c7] w-[120px] font-medium my-2 mx-2 py-1" 
              key={lng.code} 
              onClick={() => changeLanguage(lng.code)}>
              {lng.lang}
            </button>
        ))}
    </div>
  );
};

export default LanguageSelector