import React from 'react';
import Hero from './components/Hero'
import Pricing from './components/PricingComponent';
import ContactCTA from '../../components/CTA_ContactUs';
import CardsPlansInclude from '../../components/CardsPlansInclude';

function Wireless() {
  return (
    <div>

      <Hero />
      <Pricing />
      <CardsPlansInclude />
      <ContactCTA />

    </div>
  );
}

export default Wireless;
