import React from 'react';
import Hero from './components/Hero'
import CardsPbxEditions from '../../components/CardsPbxEditions';
import HostedPbxCTA from '../../components/CTA_HostedPbx';
import Banner from "./components/Banner"
import CTA from '../../components/CTA';
import ContactCTA from '../../components/CTA_ContactUs';
import Partners from '../homePage/components/partners'
import CardsUcFeatures from '../../components/CardsUcFeatures';
import FeatureSMS from './components/featureSms';
import FeatureWAW from './components/featureWaw';
import FeatureCRM from './components/featureCrm';

function HostedPbx() {
  return (
    <div>

      <Hero /> 
      <CardsUcFeatures />
      <HostedPbxCTA />
      <FeatureSMS />
      <FeatureWAW />
      <FeatureCRM />
      <Banner />
      <CardsPbxEditions />
      <CTA />
      <Partners />
      <ContactCTA />
      

    </div>
  );
}

export default HostedPbx;
