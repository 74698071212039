import React from 'react';
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../../../i18n";/* Adding new language */

  const Hero = () => {
  
    const {t} = useTranslation()
    const {hero} = t("comm_page"); /* Handle additional languages */
    

   return (
    <div className='text-[#FFF] bg-[#0a3b60]'>
        <div className='lg:max-w-[1100px] mt-[0px] mb-[16px] w-screen h-full mx-auto text-center flex flex-col justify-center py-12'>
            <h2 className='text-[#FFf] lg:text-3xl md:text-2xl sm:text-xl font-semibold p-3 sm:pb-6'><span className='text-[#FF8916] font-bold'>{hero.subtitle}</span></h2>
            
            <div className='flex sm:flex-row flex-col justify-center items-center mx-6'>
              <h1 className='lg:text-7xl md:text-5xl sm:text-4xl text-3xl font-bold md:py-2 md:ml-4 ml-2 md:my-2 my-1 sm:min-w-[450px]'>{hero.title.line1}<br/>{hero.title.line2}</h1>
            </div>

            <div className='pb-8'>
              <p className='lg:text-3xl md:text-xl sm:text-xl font-normal text-[#fff] py-4 px-6 '>
              {hero.description} 
              <br/><br/><span className='text-[#FF8916] font-bold'>{hero.note.line1}<br/>{hero.note.line2}</span></p>
            </div>
  
      </div>
    </div>
    
  )
}

export default Hero
