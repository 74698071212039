import React from 'react';
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../i18n";/* Adding new language */

const Contact_CTA = () => {
  const {t} = useTranslation()
  const {contactCta} = t("cta"); /* Handle additional languages */
  const {sales, support, partner} = t("button"); /* Handle additional languages  */

  return (
    <div className='bg-[#0A3B60] w-full py-8 text-[#fff] px-4'>
        <div className='max-w-[1240px] mx-auto grid grid-col-2 justify-center'>
            <div className='my-4 flex flex-col sm:flex-row items-center justify-between w-full'>
                <h2 className='lg:text-6xl md:text-5xl sm:text-4xl text-3xl px-6'>{contactCta}</h2>
                <div className='flex flex-col justify-center align-middle mx-6'>

                  <button className='bg-[#FF8916] hover:opacity-75 text-[#fff] rounded-md font-medium w-[180px] my-2 mx-auto py-3 mt-6 md:mt-0'><Link to="/ContactUs">{sales}</Link></button>
                  <button className='bg-[#FF8916] hover:opacity-75 text-[#fff] rounded-md font-medium w-[180px] my-2 mx-auto py-3'><Link to="/ContactUs">{support}</Link></button>
                  <button className='bg-[#FF8916] hover:opacity-75 text-[#fff] rounded-md font-medium w-[180px] my-2 mx-auto py-3'><Link to="/ContactUs">{partner}</Link></button>
  
                </div>
            </div>        
        </div>
    </div>
  )
}

export default Contact_CTA