import React from 'react';
import { useTranslation } from 'react-i18next'; /* Adding new language */
import "../../../i18n";/* Adding new language */
import Archiving from '../assets/ArchivingStorage.png';

const FeatureArchive = () => {

    const {t} = useTranslation()
    const {features} = t("contactcenter"); /* Handle additional languages */
    
    return (
        <div className='w-full bg-[#0a3b60] py-6 px-4 my-6'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
                
                <div className='align-middle justify-center pr-12 my-4 md:my-0 py-0 md:py-4 hidden md:block'>
                <img className='max-h-[330px] rounded-[10%]' src={Archiving} alt="Archiving" />
                </div>
                
                <div className='mx-6 align-middle max-h-[100%]'>

                    <h3 className='text-[#ff8916] md:text-5xl sm:text-4xl text-3xl font-bold py-6'>
                        {features.archive.title}
                    </h3>
                    <h5 className='text-[#fff] w-full max-w-[540px] text-2xl md:text-2xl pb-2'>
                        {features.archive.subtitle}
                    </h5>
                    <p className='text-[#fff] w-full max-w-[540px] text-xl py-4 text-left'>
                    {features.archive.description.line1}<br/>
                    {features.archive.description.line2}
                    </p>
                    
                </div>

            </div>
        </div>
     );
};

export default FeatureArchive;